import {requestTool, requestType, returnMode} from "@/components/cabinet/tools/requestTool";
import {useToastsStore} from "@/components/cabinet/stores/toastsStore";
import { i18n } from "@/components/i18n/i18n";
import { useCacheStore } from "@/components/cabinet/stores/cacheStore";

export class queryTool {
	requestTools = new requestTool();
	toastsStore = useToastsStore();
	cacheStore = useCacheStore();
	editObject;
	queryComponent = this;

	/**
	 * Gets sub-entities available
	 * @param edit_value {String}
	 * @return {Promise<array>}
	 */
	async getEntities(edit_value = 'true') {
		return new Promise((resolve, reject) => {
			let requestPathEntity = process.env.VUE_APP_API_V2 + 'entities';
			this.requestTools.prepareRequest(requestType.GET, {
				edit: edit_value
			});

			const currentRequest = JSON.parse(JSON.stringify(process.env.VUE_APP_API_V2 + 'entities' + this.requestTools.requestData))
			this.cacheStore.getData(currentRequest).then(cachedData => {
				let cacheData = JSON.parse(JSON.stringify(cachedData))

				if (cacheData) {
					resolve(cacheData)
				} else {
					this.requestTools.prepareRequest(requestType.GET, {
						edit: edit_value
					});
					let requestPromiseEntity = this.requestTools.doRequest(requestPathEntity, returnMode.ALL);
					requestPromiseEntity.then(value => {
						let requestLife = new Date()
						requestLife.setMinutes(requestLife.getMinutes() + 50)
						requestLife = new Date(requestLife)
						this.cacheStore.writeData(currentRequest, value.data.data, requestLife.getTime()).then(() => {
							resolve(value.data.data)
						})
					}).catch(error => {
						if (error.error === 'Not Found') {
							resolve([]);
						} else {
							reject(error);
						}
					});
				}
			})
		});
	}

	/**
	 * Gets services available
	 * @param main {String}
	 * @return {Promise<array>}
	 */
	async getServices(main = 1) {
		return new Promise((resolve, reject) => {
			let requestPathEntity = process.env.VUE_APP_API_V2 + 'services';
			this.requestTools.prepareRequest(requestType.GET, {
				main: main
			});

			const currentRequest = JSON.parse(JSON.stringify(process.env.VUE_APP_API_V2 + 'services' + this.requestTools.requestData))
			this.cacheStore.getData(currentRequest).then(cachedData => {
				let cacheData = JSON.parse(JSON.stringify(cachedData))

				if (cacheData) {
					resolve(cacheData)
					return
				}

				this.requestTools.prepareRequest(requestType.GET, {
					main: main
				});
				let requestPromiseEntity = this.requestTools.doRequest(requestPathEntity, returnMode.ALL);
				requestPromiseEntity.then(value => {
					let requestLife = new Date()
					requestLife.setMinutes(requestLife.getMinutes() + 50)
					requestLife = new Date(requestLife)
					this.cacheStore.writeData(currentRequest, value.data.data, requestLife.getTime()).then(() => {
						resolve(value.data.data)
					})
				}).catch(error => {
					if (error.error === 'Not Found') {
						resolve([]);
					} else {
						reject(error);
					}
				});
			})
		});
	}

	/**
	 * Gets media types depending on entity
	 * @param entity {string}
	 * @return {Promise<array>}
	 */
	async getMediaTypes(entity = this.editObject.entity.name) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/media/types';
			this.requestTools.prepareRequest(requestType.GET, {
				entity: entity
			});

			const currentRequest = JSON.parse(JSON.stringify(requestPath + this.requestTools.requestData))
			this.cacheStore.getData(currentRequest).then(cachedData => {
				let cacheData = JSON.parse(JSON.stringify(cachedData))

				if (cacheData) {
					resolve(cacheData)
					return
				}

				this.requestTools.prepareRequest(requestType.GET, {
					entity: entity
				});
				let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
				requestPromise.then(value => {
					let requestLife = new Date()
					requestLife.setMinutes(requestLife.getMinutes() + 50)
					requestLife = new Date(requestLife)
					this.cacheStore.writeData(currentRequest, value.data.data, requestLife.getTime()).then(() => {
						resolve(value.data.data)
					})
				}).catch(error => {
					if (error.error === 'Not Found') {
						resolve([]);
					} else {
						reject(error);
					}
				});
			})
		});
	}

	/**
	 * Gets media depending on entity
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async getMedia(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/media';
			this.requestTools.prepareRequest(requestType.GET, {
				model_id: model_id,
				entity: entity
			});

			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromise.then(value => {
				resolve(value.data)
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Creates logo data depending on entity
	 * @param entity {string}
	 * @param model_id {string||id}
	 * @return {Promise<array>}
	 */
	async createLogoData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let data = {};

			data.items = [];
			data.id = parseInt(model_id);
			data.entity_id = null;

			Promise.all([
				this.getLogo(entity, model_id),
			]).then(values => {
				let media = values[0];

				let typesarray = [
					{
						title: "Logo",
						id: 1
					}
				]

				for (let i = 0; i < typesarray.length; i++) {
					let typesItem = {};
					typesItem.title = typesarray[i].title;
					typesItem.itemsTitle = typesarray[i].title;
					typesItem.type_id = typesarray[i].id;
					typesItem.id = data.id;
					typesItem.entity = entity;

					if (media !== undefined && media !== null && Object.keys(media).length > 0) {
						typesItem.items = [
							{
								id: 0,
								model_id: model_id,
								media: media.media,
								server: media.server
							}
						];
					} else {
						typesItem.items = [];
					}

					data.items.push(typesItem);
				}

				resolve(data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Creates media data depending on entity
	 * @param entity {string}
	 * @param model_id {string||id}
	 * @return {Promise<array>}
	 */
	async createMediaData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let data = {};

			data.items = [];
			data.id = parseInt(model_id);
			data.entity_id = null;

			Promise.all([
				this.getEntities('true'),
				this.getMediaTypes(entity),
				this.getMedia(entity, model_id),
				this.getEntities('false')
			]).then(values => {
				let entitiesArray = values[0].concat(values[3]);
				data.entity_id = entitiesArray.find(dataItem => dataItem.name === entity).id
				let typesarray = values[1];
				let media = values[2];

				for (let i = 0; i < typesarray.length; i++) {
					let typesItem = {};
					typesItem.title = typesarray[i].title;
					typesItem.itemsTitle = typesarray[i].title;
					typesItem.type_id = typesarray[i].id;
					typesItem.id = data.id;
					typesItem.entity = entity;

					typesItem.items = media.data.filter(image => image.type_id === typesarray[i].id);
					data.items.push(typesItem);
				}

				resolve(data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Gets descriptions depending on entity
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async getDescriptions(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'descriptions';
			this.requestTools.prepareRequest(requestType.GET, {
				model_id: model_id,
				entity: entity
			});
			let requestPromiseDescriptions = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromiseDescriptions.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Gets descriptions list depending on entity
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @param service_id {string||number}
	 * @return {Promise<array>}
	 */
	async getDescriptionsList(
		entity = this.editObject.entity.name,
		model_id = this.editObject.id,
		service_id = null
	) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'descriptions/lists';
			let dataToGet = {
				model_id: model_id,
				entity: entity,
				service_id: service_id
			};

			if (dataToGet.service_id === null)
				delete dataToGet.service_id;

			if (dataToGet.service_id !== null)
				delete dataToGet.model_id;

			this.requestTools.prepareRequest(requestType.GET, dataToGet);

			const currentRequest = JSON.parse(JSON.stringify(requestPath + this.requestTools.requestData))

			this.cacheStore.getData(currentRequest).then(cachedData => {
				let cacheData = JSON.parse(JSON.stringify(cachedData))

				if (cacheData) {
					resolve(cacheData)
					return
				}

				this.requestTools.prepareRequest(requestType.GET, dataToGet);
				let requestPromiseDescriptionsList = this.requestTools.doRequest(requestPath, returnMode.ALL);
				requestPromiseDescriptionsList.then(value => {
					let requestLife = new Date()
					requestLife.setMinutes(requestLife.getMinutes() + 50)
					requestLife = new Date(requestLife)
					this.cacheStore.writeData(currentRequest, value.data.data, requestLife.getTime()).then(() => {
						resolve(value.data.data)
					})
				}).catch(error => {
					if (error.error === 'Not Found') {
						resolve([]);
					} else {
						reject(error);
					}
				});
			})
		});
	}

	/**
	 * Creates data for descriptions
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async createDescriptionsData(
		entity = this.editObject.entity.name,
		model_id = this.editObject.id) {
		return new Promise((resolve) => {
			let descrData = {};

			descrData.model_id = parseInt(model_id);
			descrData.id = this.editObject.id;
			descrData.entity = entity;
			descrData.items = [];

			Promise.all([
				this.getDescriptions(entity, model_id),
				this.getDescriptionsList(entity, model_id)
			]).then(values => {
				let descrarray = values[0];
				let descrCategoryarray = values[1];

				for (let i = 0; i < descrCategoryarray.length; i++) {
					let descrItem = {};
					descrItem.title = descrCategoryarray[i].title;
					descrItem.items = descrarray.filter(descriptionItem =>
						parseInt(descriptionItem.type_id) === parseInt(descrCategoryarray[i].id));
					descrItem.entity = descrData.entity;
					descrItem.model_id = descrData.id;
					descrItem.type_id = descrCategoryarray[i].id;
					descrData.items.push(descrItem);
				}

				resolve(descrData);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		})
	}

	/**
	 * Creates data for descriptions
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async createDirectionDescriptionsData(
		entity = this.editObject.entity.name,
		model_id = this.editObject.id) {
		return new Promise((resolve) => {
			let descrData = {};

			descrData.model_id = parseInt(model_id);
			descrData.id = this.editObject.id;
			descrData.entity = entity;
			descrData.items = [];

			Promise.all([
				this.getDescriptions(entity, model_id),
				this.getDescriptionsList('service', null, this.editObject.service.id)
			]).then(values => {
				let descrarray = values[0];
				let descrCategoryarray = values[1];

				for (let i = 0; i < descrCategoryarray.length; i++) {
					let descrItem = {};
					descrItem.title = descrCategoryarray[i].title;
					descrItem.items = descrarray.filter(descriptionItem =>
						parseInt(descriptionItem.type_id) === parseInt(descrCategoryarray[i].id));
					descrItem.entity = descrData.entity;
					descrItem.model_id = descrData.id;
					descrItem.type_id = descrCategoryarray[i].id;
					descrData.items.push(descrItem);
				}

				resolve(descrData);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		})
	}

	/**
	 * Creates data for media
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async createDirectionMediaData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let data = {};

			data.items = [];
			data.id = parseInt(model_id);
			data.entity_id = null;

			Promise.all([
				this.getEntities('true'),
				this.getMediaTypes('service'),
				this.getMedia(entity, model_id),
				this.getEntities('false')
			]).then(values => {
				let entitiesArray = values[0].concat(values[3]);
				data.entity_id = entitiesArray.find(dataItem => dataItem.name === entity).id
				let typesarray = values[1];
				let media = values[2];

				for (let i = 0; i < typesarray.length; i++) {
					let typesItem = {};
					typesItem.title = typesarray[i].title;
					typesItem.itemsTitle = typesarray[i].title;
					typesItem.type_id = typesarray[i].id;
					typesItem.id = data.id;
					typesItem.entity = entity;

					typesItem.items = media.data.filter(image => image.type_id === typesarray[i].id);
					data.items.push(typesItem);
				}

				resolve(data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Creates data for notes
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async createNotesData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve) => {
			let data = {};

			data.id = model_id;
			data.entity = entity;
			data.items = [];

			let requestPathEntity = process.env.VUE_APP_API_V2 + 'notes';
			this.requestTools.prepareRequest(requestType.GET, {
				model_id: model_id,
				entity: entity
			});

			let requestPromiseNotes = this.requestTools.doRequest(requestPathEntity, returnMode.ALL);
			requestPromiseNotes.then(notesValue => {
				data.items = notesValue.data.data;
				resolve(data);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		});
	}
	async createPrivateNotesData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve) => {
			let data = {};

			data.id = model_id;
			data.entity = entity;
			data.items = [];

			let requestPathEntity = process.env.VUE_APP_API_V2 + 'notes/private';
			this.requestTools.prepareRequest(requestType.GET, {
				model_id: model_id,
				entity: entity
			});

			let requestPromiseNotes = this.requestTools.doRequest(requestPathEntity, returnMode.ALL);
			requestPromiseNotes.then(notesValue => {
				data.items = notesValue.data.data;
				resolve(data);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		});
	}


	/**
	 * Creates data for alerts
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async createAlertsData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve) => {
			let data = {};

			data.id = model_id;
			data.entity = entity;
			data.items = [];

			let requestPathEntity = process.env.VUE_APP_API_V2 + 'alerts';
			this.requestTools.prepareRequest(requestType.GET, {
				model_id: model_id,
				entity: entity
			});

			let requestPromiseAlerts = this.requestTools.doRequest(requestPathEntity, returnMode.ALL);
			requestPromiseAlerts.then(alertsValue => {
				data.items = alertsValue.data.data;
				resolve(data);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		});
	}

	/**
	 * Returns specified item array from the server
	 * @param entity {Object || String}
	 * @param search {String}
	 * @return {Promise<Object>}
	 */
	async getItems(entity, search) {
		return new Promise((resolve, reject) => {
			let currentItem = {};
			let currentEntity = '';


			if (typeof entity === 'object') {
				currentEntity = entity.name;
			} else if (typeof entity === 'string') {
				currentEntity = entity;
			} else {
				reject({});
			}

			switch (currentEntity) {
				case 'airport':
					this.getAirports(search).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'note':
					this.getNotes().then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'private_note':
					this.getPrivateNotes().then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'alert':
					this.getAlerts().then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'provider':
					this.getProviders(search).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'providers':
					this.getProviders(search).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'currency':
					this.getCurrencies(search).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				default:
					reject({});
					break;
			}
		});
	}

	/**
	 * Returns specified item or rejects if any params are wrong
	 * @param entity {Object || String}
	 * @param item_id {Number || String}
	 * @return {Promise<Object>}
	 */
	async getItem(entity, item_id) {
		return new Promise((resolve, reject) => {
			let currentItem = {};
			let currentID = item_id;
			let currentEntity = '';

			if (typeof entity === 'object') {
				currentEntity = entity.name;
			} else if (typeof entity === 'string') {
				currentEntity = entity;
			} else {
				reject({});
			}

			switch (currentEntity) {
				case 'airport':
					this.getAirport(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'currency':
					this.getCurrency(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'terminal':
					this.getTerminal(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'city':
					this.getCity(currentID).then((value) => {
						currentItem = value;
						resolve(currentItem);
					});
					break;

				case 'note':
					this.getNote(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'private_note':
					this.getPrivateNote(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'alert':
					this.getAlert(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'provider':
					this.getProvider(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'provider_worker':
					this.getProviderWorker(currentID).then(value => {
						currentItem = value;
						resolve(currentItem);
					})
					break;

				case 'variable_block':
					this.getVariableBlocks(currentID, '0,1').then(value => {
						currentItem = value;
						resolve(currentItem);
					});
					break;

				case 'terminal_service':
					resolve({
						id: currentID,
						entity: {
							name: currentEntity
						}
					});
					break;

				default:
					reject({});
					break;
			}
		});
	}

	/**
	 * Get items by entities link
	 * @param request_link {string}
	 * @param searchInput {string}
	 * @return {Promise<array>}
	 */
	async getItemsByLink(request_link, searchInput = '') {
		return new Promise((resolve) => {
			let requestPath = request_link;
			this.requestTools.prepareRequest(requestType.GET, {
				search: searchInput
			});

			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromise.then(values => {
				resolve(values.data.data);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		});
	}

	/**
	 * Get items by entities link
	 * @param request_link {string}
	 * @param model_id {string}
	 * @return {Promise<array>}
	 */
	async getItemByLink(request_link, model_id = '') {
		return new Promise((resolve) => {
			let requestPath = request_link + '/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {

			});

			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromise.then(values => {
				resolve(values.data.data);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		});
	}

	/**
	 * Creates variables data depending on entity
	 * @return {Promise<array>}
	 */
	async createVariablesData() {
		return new Promise((resolve) => {
			let data = {};

			data.id = this.editObject.id;
			data.entity = this.editObject.entity;
			data.variables_blocks = [];
			data.variables = [];

			this.getVariableBlocks(data.entity.name).then(values => {
				data.variables_blocks = values;
				let currentItem = {};

				this.getItem(data.entity.name, data.id).then(value => {
					currentItem = value;

					if (currentItem.variables.items === undefined) {
						data.variables = currentItem.variables;
					} else {
						data.variables = currentItem.variables.items;
					}
					data.variables_langs = currentItem.variables.langs;
					data.show_user_types = currentItem.variables.show_user_types;

					if (!Array.isArray(data.variables_langs)) {
						data.variables_langs = [];
					}

					if (data.variables_langs.length === 0) {
						this.getLanguages().then(langArray => {
							let langRusItem = langArray.find(langItem => langItem.iso2 === 'RUS');
							data.variables_langs.push(langRusItem);
							let langEngItem = langArray.find(langItem => langItem.iso2 === 'ENG');
							data.variables_langs.push(langEngItem);

							resolve(JSON.parse(JSON.stringify(data)));
						});
					} else {
						resolve(JSON.parse(JSON.stringify(data)));
					}
				});
			}).catch(function() {
				resolve({
					id: 0,
					variables: [],
					variables_blocks: [],
					variables_langs: []
				});
			});
		});
	}

	/*
	* Creates all data in order:
	* images, descriptions, notes, alerts
	* @return {Promise<array>}
	* */
	async createAllData() {
		return new Promise((resolve) => {
			Promise.all([
				this.createMediaData(),
				this.createDescriptionsData(),
				this.createNotesData(),
				this.createAlertsData(),
				this.createPrivateNotesData(),
			]).then(values => {
				resolve(values);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		});
	}

	/**
	 * Returns an array of languages based on input
	 * @param input {string}
	 * @return {Promise<array>}
	 */
	async getLanguages(input = '') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'languages';
			this.requestTools.prepareRequest(requestType.GET, {
				search: input
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of currencies based on input
	 * @param input {string}
	 * @return {Promise<array>}
	 */
	async getCurrencies(input = '') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'currencies';
			this.requestTools.prepareRequest(requestType.GET, {
				search: input
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of currencies based on input
	 * @return {Promise<array>}
	 */
	async getCurrencyFormTypes() {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'currency_markup/types';
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of currencies based on input
	 * @return {Promise<array>}
	 */
	async getCurrencyMarkupList() {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'currency_markup/currencies';
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of currencies based on input
	 * @param model_id {string || number}
	 * @return {Promise<array>}
	 */
	async getCurrency(model_id = '') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'currencies/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			this.getCurrencyMarkupList().then(valueTypes => {
				let currVal = valueTypes.find(item => item.id === parseInt(model_id))
				requestPromise.then(value => {

					let currentData = value.data.data

					if (currVal) {
						currentData = { ...currentData, ...currVal }
					}

					resolve(currentData);
				}).catch(error => {
					if (error.error === 'Not Found') {
						resolve([]);
					} else {
						reject(error);
					}
				});
			})
		});
	}

	/**
	 * Returns an array of countries based on input
	 * @param input {string}
	 * @param elements {string}
	 * @param page {number}
	 * @param pageSize {number}
	 * @param sortBy {string}
	 * @return {Promise<array>}
	 */
	async getCountries (input, elements = '', page = 1, pageSize = 10, sortBy = 'name,asc') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'countries';
			let dataToSend = {
				'sortBy': sortBy,
				'page': page,
				'pageSize': pageSize,
				'elements': elements === undefined || elements === null ? '' : elements,
				'search': input === undefined || input === null ? '' : input
			};

			if (elements === '')
				delete dataToSend.elements;

			this.requestTools.prepareRequest(requestType.GET, dataToSend);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of cities based on input
	 * @param input {string}
	 * @param elements {string}
	 * @param page {number}
	 * @param pageSize {number}
	 * @param sortBy {string}
	 * @return {Promise<array>}
	 */
	async getCities (input, elements = '', page = 1, pageSize = 10, sortBy = 'name,asc') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'cities';
			let dataToSend = {
				'sortBy': sortBy,
				'page': page,
				'pageSize': pageSize,
				'elements': elements === undefined || elements === null ? '' : elements,
				'search': input === undefined || input === null ? '' : input
			};

			if (elements === '')
				delete dataToSend.elements;

			this.requestTools.prepareRequest(requestType.GET, dataToSend);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a city object
	 * @param model_id {string || number}
	 * @return {Promise<array>}
	 */
	async getCity (model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'cities/' + parseInt(model_id);

			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (!value.data.data) {
					resolve(value.data);
				} else {
					resolve(value.data.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Upload a media object
	 * Always set editObject to formdata!
	 * @param type {String}
	 * @return {Promise<number>}
	 */
	async uploadMedia(type = 'media') {
		return new Promise((resolve, reject) => {
			let requestPath = '';

			let currentID = this.editObject.get('id');
			if (currentID !== undefined && currentID !== null) {
				requestPath = process.env.VUE_APP_API_V2 + 'admin/media/' + this.editObject.get('id');
			} else {
				requestPath = process.env.VUE_APP_API_V2 + 'admin/media/';
			}

			if (type === 'logo') {
				delete this.editObject.type_id;
			}

			this.requestTools.prepareRequest(requestType.POST, this.editObject);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL, 'multipart/form-data');
			let component = this;

			requestPromise.then(value => {
				let toast = {};
				toast.title = i18n.t('alerts.success');
				toast.description = i18n.t('alerts.success_upload');
				toast.status = 'success';
				toast.time = 4000;
				component.toastsStore.addToast(toast);

				if (value.data.model_id || ( value.data.data && value.data.data.model_id) ) {
					if (value.data.model_id === undefined) {
						resolve(value.data.data.model_id);
					} else {
						resolve(value.data.model_id);
					}
				} else {
					resolve(value.data.id);
				}
			}).catch(error => {
				let toast = {};
				toast.title = i18n.t('alerts.error');
				toast.description = error;
				toast.status = 'danger';
				toast.payload = error;
				toast.description += "<br><br>";
				toast.description += error.message;
				toast.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toast.description += " " + value + "<br>";
					}
				} else {
					console.log(error);
				}
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				reject(error);
			});
		});
	}

	/**
	 * Save a media object
	 * Always set editObject to formdata!
	 * @param type {String}
	 * @return {Promise<number>}
	 */
	async saveMedia(type = 'media') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/media/' + this.editObject.get('id');
			let sendObject = {};
			sendObject = this.editObject;
			if (type === 'logo') {
				delete sendObject.type_id;
			}

			this.requestTools.prepareRequest(requestType.POST, sendObject);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL, 'multipart/form-data');
			let component = this;

			requestPromise.then(value => {
				let toast = {};
				toast.title = i18n.t('alerts.success');
				toast.description = i18n.t('alerts.success_save');
				toast.status = 'success';
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				if (value.data.model_id === undefined) {
					resolve(value.data.data.model_id);
				} else {
					resolve(value.data.model_id);
				}
			}).catch(error => {
				let toast = {};
				toast.title = i18n.t('alerts.error');
				toast.description = error;
				toast.status = 'danger';
toast.payload = error;
				toast.description += "<br><br>";
				toast.description += error.message;
				toast.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toast.description += " " + value + "<br>";
					}
				}
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				reject(error);
			});
		});
	}

	/**
	 * Deletes current object. Returns number of deleted object
	 * @return {Promise<number>}
	 */
	async deleteObject() {
		return new Promise((resolve, reject) => {
			if (this.editObject.id !== undefined && this.editObject.id !== null && this.editObject.id !== 0) {
				let requestPath = '';

				switch (this.editObject.entity.name) {
					case 'airport':
						requestPath = process.env.VUE_APP_API_V2 + 'admin/airports/' + this.editObject.id;
						break;

					case 'terminal':
						requestPath = process.env.VUE_APP_API_V2 + 'admin/terminals/' + this.editObject.id;
						break;

					case 'terminal_service':
						requestPath = process.env.VUE_APP_API_V2 + 'terminal_services/' + this.editObject.id;
						break;

					case 'terminal_provider_serivce':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/services/' + this.editObject.id;
						break;

					case 'alert':
						requestPath = process.env.VUE_APP_API_V2 + 'alerts/' + this.editObject.id;
						break;

					case 'city':
						requestPath = process.env.VUE_APP_API_V1 + 'cities/' + this.editObject.id;
						break;

					case 'currency_markup':
						requestPath = process.env.VUE_APP_API_V1 + 'currency_markup/' + this.editObject.id;
						break;

					case 'currency_markup_total':
						requestPath = process.env.VUE_APP_API_V1 + 'currency_markup/global'
						break;

					case 'note':
						requestPath = process.env.VUE_APP_API_V2 + 'notes/' + this.editObject.id;
						break;

					case 'private_note':
						requestPath = process.env.VUE_APP_API_V2 + 'notes/private/' + this.editObject.id;
						break;

					case 'description':
						requestPath = process.env.VUE_APP_API_V2 + 'descriptions/' + this.editObject.id;
						break;

					case 'lang':
						requestPath = process.env.VUE_APP_API_V2 + 'languages/langs/' + this.editObject.id;
						break;

					case 'media':
						requestPath = process.env.VUE_APP_API_V2 + 'admin/media/' + this.editObject.id;
						break;

					case 'logo':
						requestPath = process.env.VUE_APP_API_V2 + 'admin/media/delete_logo';
						break;

					case 'provider_auth':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/auth/' + this.editObject.id;
						break;

					case 'provider_auth_site':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/auth_site/' + this.editObject.id;
						break;

					case 'provider':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/' + this.editObject.id;
						break;

					case 'provider_connection':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/terminals/' + this.editObject.id;
						break;

					case 'terminal_connection':
						requestPath = process.env.VUE_APP_API_V2 + 'terminals/providers/' + this.editObject.id;
						break;

					case 'provider_variable':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/variables/' + this.editObject.id;
						break;

					case 'provider_worker_variable':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/variables/' + this.editObject.id;
						break;

					case 'provider_worker':
						requestPath = process.env.VUE_APP_API_V2 + 'workers/' + this.editObject.id;
						break;

					case 'variable':
						requestPath = process.env.VUE_APP_API_V2 + 'variables/' + this.editObject.id;
						break;

					case 'variable_block':
						requestPath = process.env.VUE_APP_API_V2 + 'variables/blocks/' + this.editObject.id;
						break;

					case 'service':
						requestPath = process.env.VUE_APP_API_V2 + 'services/' + this.editObject.id;
						break;

					case 'terminal_provider_service':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/services/' + this.editObject.id;
						break;

					case 'terminal_additional_service':
						requestPath = process.env.VUE_APP_API_V1 + 'terminal_services/terminal/' + this.editObject.terminal_id;
						break;

					case 'terminal_additional_service_element':
						requestPath = process.env.VUE_APP_API_V1 + 'terminals/' + this.editObject.terminal_id + '/additional_services/' + this.editObject.id;
						break;

					case 'comfort':
						requestPath = process.env.VUE_APP_API_V2 + 'comforts/' + this.editObject.id;
						break;

					case 'comfort_categories':
						requestPath = process.env.VUE_APP_API_V2 + 'comforts/categories/' + this.editObject.id;
						break;

					default:
						reject('! UNSUPPORTED TYPE !');
						break;
				}

				if (this.editObject.entity.name === 'logo') {
					this.requestTools.prepareRequest(requestType.POST, {
						entity: this.editObject.entity.name,
						model_id: this.editObject.id
					});
					let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
					let component = this;

					requestPromise.then(value => {
						let toast = {};
						toast.title = i18n.t('alerts.success');
						toast.description = i18n.t('alerts.success_delete');
						toast.status = 'success';
						toast.time = 4000;
						component.toastsStore.addToast(toast);
						if (value.data.id === undefined) {
							resolve(value.data.data.id);
						} else {
							resolve(value.data.id);
						}
					}).catch(error => {
						let toast = {};
						toast.title = i18n.t('alerts.error');
						toast.description = error;
						toast.status = 'danger';
						toast.payload = error;
						toast.description += "<br><br>";
						toast.description += error.message;
						toast.description += "<br>";
						if (error.errors !== undefined) {
							for (const [value] of Object.entries(error.errors)) {
								toast.description += " " + value + "<br>";
							}
						}
						toast.time = 4000;
						component.toastsStore.addToast(toast);
						reject(error);
					});
				} else {
					this.requestTools.prepareRequest(requestType.DELETE, {});
					let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
					let component = this;

					requestPromise.then(value => {
						let toast = {};
						toast.title = i18n.t('alerts.success');
						toast.description = i18n.t('alerts.success_delete');
						toast.status = 'success';
						toast.time = 4000;
						component.toastsStore.addToast(toast);
						if (value.data.model_id && value.data) {
							if (value.data.model_id === undefined) {
								resolve(value.data.data.model_id);
							} else {
								resolve(value.data.model_id);
							}
						} else {
							resolve(this.editObject.id)
						}
					}).catch(error => {
						let toast = {};
						toast.title = i18n.t('alerts.error');
						toast.description = error;
						toast.status = 'danger';
toast.payload = error;
						toast.description += "<br><br>";
						toast.description += error.message;
						toast.description += "<br>";
						if (error.errors !== undefined) {
							for (const [value] of Object.entries(error.errors)) {
								toast.description += " " + value + "<br>";
							}
						}
						toast.time = 4000;
						component.toastsStore.addToast(toast);
						reject(error);
					});
				}
			} else {
				resolve(0);
			}
		});
	}

	/**
	 * Deletes all data of current object.
	 * @return {Promise<number>}
	 */
	async deleteAllData() {
		return new Promise((resolve, reject) => {
			if (this.editObject.id !== undefined && this.editObject.id !== null && this.editObject.id !== 0) {
				let requestPath = '';

				switch (this.editObject.entity.name) {
					case 'provider_connection':
						requestPath = process.env.VUE_APP_API_V2 + 'providers/terminals/all/' + this.editObject.id;
						break;

					default:
						reject('! UNSUPPORTED TYPE !');
						break;
				}

				this.requestTools.prepareRequest(requestType.DELETE, {});
				let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
				let component = this;

				requestPromise.then(function() {
					let toast = {};
					toast.title = i18n.t('alerts.success');
					toast.description = i18n.t('alerts.success_delete');
					toast.status = 'success';
					toast.time = 4000;
					component.toastsStore.addToast(toast);
					resolve(0);
				}).catch(error => {
					let toast = {};
					toast.title = i18n.t('alerts.error');
					toast.description = error;
					toast.status = 'danger';
toast.payload = error;
					toast.description += "<br><br>";
					toast.description += error.message;
					toast.description += "<br>";
					if (error.errors !== undefined) {
						for (const [value] of Object.entries(error.errors)) {
							toast.description += " " + value + "<br>";
						}
					}
					toast.time = 4000;
					component.toastsStore.addToast(toast);
					reject(error);
				});
			} else {
				resolve(0);
			}
		});
	}

	/**
	 * Deletes an object depending on entity and its id. Returns number of deleted object
	 * @return {Promise<number>}
	 * @param entityToDelete {string}
	 * @param idToDelete {string || number}
	 */
	async deleteSpecificObject(entityToDelete, idToDelete) {
		return new Promise((resolve, reject) => {
			if (idToDelete !== undefined && idToDelete !== null && idToDelete !== 0) {
				let objectToDelete = '';
				let requestPath = '';

				switch (entityToDelete) {
					case 'airport':
						objectToDelete = 'airports';
						requestPath = `/api/v2/admin/${objectToDelete}/` + idToDelete;
						break;

					case 'terminal':
						objectToDelete = 'terminals';
						requestPath = `/api/v2/admin/${objectToDelete}/` + idToDelete;
						break;

					case 'terminal_service':
						objectToDelete = 'terminal_services';
						requestPath = `/api/v2/admin/${objectToDelete}/` + idToDelete;
						break;

					case 'alert':
						objectToDelete = 'alerts';
						requestPath = `/api/v2/${objectToDelete}/` + idToDelete;
						break;

					case 'note':
						objectToDelete = 'notes';
						requestPath = `/api/v2/${objectToDelete}/` + idToDelete;
						break;

					case 'private_note':
						objectToDelete = 'notes/private';
						requestPath = `/api/v2/${objectToDelete}/` + idToDelete;
						break;

					case 'description':
						objectToDelete = 'descriptions';
						requestPath = `/api/v2/${objectToDelete}/` + idToDelete;
						break;

					case 'lang':
						objectToDelete = 'langs';
						requestPath = `/api/v1/languages/${objectToDelete}/` + idToDelete;
						break;
				}

				this.requestTools.prepareRequest(requestType.DELETE, {});
				let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
				let component = this;

				requestPromise.then(value => {
					let toast = {};
					toast.title = i18n.t('alerts.success');
					toast.description = i18n.t('alerts.success_delete');
					toast.status = 'success';
					toast.time = 4000;
					component.toastsStore.addToast(toast);
					resolve(value.data.model_id);
				}).catch(error => {
					let toast = {};
					toast.title = i18n.t('alerts.error');
					toast.description = error;
					toast.status = 'danger';
toast.payload = error;
					toast.description += "<br><br>";
					toast.description += error.message;
					toast.description += "<br>";
					if (error.errors !== undefined) {
						for (const [value] of Object.entries(error.errors)) {
							toast.description += " " + value + "<br>";
						}
					}
					toast.time = 4000;
					component.toastsStore.addToast(toast);
					reject(error);
				});
			} else {
				resolve(0);
			}
		});
	}

	/**
	 * Returns an array of airports based on input
	 * @param input {string}
	 * @param elements {string}
	 * @param page {number || string}
	 * @param pageSize {number || string}
	 * @param sortBy {string}
	 * @return {Promise<array>}
	 */
	async getAirports (input, elements = '', page = 1, pageSize = 10, sortBy = 'name,asc') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/airports';
			let dataToSend = {
				'sortBy': sortBy,
				'page': page,
				'pageSize': pageSize,
				'elements': elements === undefined || elements === null ? '' : elements,
				'search': input === undefined || input === null ? '' : input
			};

			if (elements === '')
				delete dataToSend.elements;

			this.requestTools.prepareRequest(requestType.GET, dataToSend);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an airport object or rejects if does not exists
	 * @param airport_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getAirport (airport_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/airports/' + airport_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a note object or rejects if does not exists
	 * @param note_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getNote (note_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'notes/' + note_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	async getPrivateNote (note_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'notes/private/' + note_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a provider worker object or rejects if does not exists
	 * @param provider_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getProviderWorker (provider_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'workers/' + provider_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of terminal services
	 * @param terminal_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getTerminalServices (terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'terminal_services';
			this.requestTools.prepareRequest(requestType.GET, {
				terminal_id: terminal_id
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a terminal service
	 * @param model_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getTerminalService (model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'terminal_services/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a terminal additional service
	 * @param model_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getTerminalAdditionalService (model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'terminal_additional_services/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a terminal additional service array
	 * @param model_id {string || number}
	 * @returns {Promise<array>}
	 */
	async getTerminalAdditionalServicesMulti(model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'terminal_service_additional_service/terminal_service/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a terminal additional service array
	 * @param model_id {string || number}
	 * @returns {Promise<array>}
	 */
	async getTerminalAdditionalServicesMultiDirection(model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'direction_services/' + model_id + '/additional_services/';
			this.requestTools.prepareRequest(requestType.GET, {
				pageSize: 99
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a terminal additional service array
	 * @param model_id {string || number}
	 * @returns {Promise<array>}
	 */
	async getTerminalAdditionalServicesMultiTerminalService(model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'terminal_services/' + model_id + '/additional_services/';
			this.requestTools.prepareRequest(requestType.GET, {
				pageSize: 99
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of terminal services with providers
	 * @param terminal_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getTerminalProviderServices (terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/terminal/' + terminal_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional services
	 * @param terminal_id {string||number}
	 * @return {Promise<array>}
	 */
	async getTerminalAdditionalServices(terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'terminal_additional_services';
			this.requestTools.prepareRequest(requestType.GET, {
				terminal_id: terminal_id
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional service of terminal
	 * @param model_id {string||number}
	 * @param terminal_id {string||number}
	 * @return {Promise<object>}
	 */
	async getTerminalAdditionalServiceOfTerminal(model_id, terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'terminals/' + terminal_id + '/additional_services/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {

			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional service
	 * @param model_id {string||number}
	 * @param object_id {string||number}
	 * @return {Promise<object>}
	 */
	async getTerminalAdditionalServiceOfDirection(model_id, object_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'direction_services/' + object_id + '/additional_services/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {

			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional service
	 * @param model_id {string||number}
	 * @param object_id {string||number}
	 * @return {Promise<object>}
	 */
	async getTerminalAdditionalServiceOfTerminalService(model_id, object_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'terminal_services/' + object_id + '/additional_services/' + model_id;
			this.requestTools.prepareRequest(requestType.GET, {

			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional services for provider
	 * @param terminal_id {string||number}
	 * @param provider_id {string||number}
	 * @return {Promise<array>}
	 */
	async getDirectionAdditionalServices(terminal_id, provider_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/additional/terminal/' + terminal_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromise.then(value => {
				let providerData = value.data.data[provider_id];

				if (providerData) {
					resolve(providerData.additional_services);
				} else {
					resolve([]);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional services objects array
	 * @param terminal_id {string||number}
	 * @return {Promise<array>}
	 */
	async getTerminalAdditionalServicesObjects(terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'terminal_additional_services/all';
			this.requestTools.prepareRequest(requestType.GET, {
				terminal_id: terminal_id
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional services objects array
	 * @param service_id {string||number}
	 * @return {Promise<array>}
	 */
	async getTerminalAdditionalServicesObjectsOfTerminalService(service_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'terminal_services/' + service_id + '/additional_services/'
			this.requestTools.prepareRequest(requestType.GET, {
				pageSize: 99
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional services objects array
	 * @param direction_id {string||number}
	 * @return {Promise<array>}
	 */
	async getTerminalAdditionalServicesObjectsOfDirection(direction_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'direction_services/' + direction_id + '/additional_services/'
			this.requestTools.prepareRequest(requestType.GET, {
				pageSize: 99
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Fetches additional services objects array
	 * @param direction_id {string||number}
	 * @return {Promise<array>}
	 */
	async getDirectionAdditionalServicesObjects(direction_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'terminal_additional_services/all';
			this.requestTools.prepareRequest(requestType.GET, {
				direction_id: direction_id
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an object of direction
	 * @param direction_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getDirectionObject (direction_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/directions/' + direction_id;
			this.requestTools.prepareRequest(requestType.GET, {});

			const currentRequest = JSON.parse(JSON.stringify(requestPath + this.requestTools.requestData))
			this.cacheStore.getData(currentRequest).then(cachedData => {
				let cacheData = JSON.parse(JSON.stringify(cachedData))

				if (cacheData) {
					resolve(cacheData)
					return
				}

				this.requestTools.prepareRequest(requestType.GET, {});
				let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

				requestPromise.then(value => {
					if (value.data.data !== undefined) {
						let requestLife = new Date()
						requestLife.setMinutes(requestLife.getMinutes() + 50)
						requestLife = new Date(requestLife)
						this.cacheStore.writeData(currentRequest, value.data.data, requestLife.getTime()).then(() => {
							resolve(value.data.data)
						})
					} else {
						let requestLife = new Date()
						requestLife.setMinutes(requestLife.getMinutes() + 50)
						requestLife = new Date(requestLife)
						this.cacheStore.writeData(currentRequest, value.data, requestLife.getTime()).then(() => {
							resolve(value.data)
						})
					}
				}).catch(error => {
					if (error.error === 'Not Found') {
						resolve({});
					} else {
						reject(error);
					}
				});
			})
		});
	}

	/**
	 * Returns a terminal object or rejects if does not exists
	 * @param terminal_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getTerminalById (terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/terminals/' + terminal_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Saves or creates current object
	 * @returns {Promise<object>}
	 */
	async saveObject () {
		return new Promise((resolve, reject) => {
			let requestPath = '';
			let entityToSaveIn = this.editObject.entity.name;
			let objectToSave = '';
			let entityToSave = this.editObject.saveEntity;

			switch (entityToSaveIn) {
				case 'airport':
					objectToSave = 'airports';
					requestPath = `/api/v2/admin/${objectToSave}/`;
					break;

				case 'terminal':
					objectToSave = 'terminals';
					requestPath = `/api/v2/admin/${objectToSave}/`;
					break;

				case 'terminal_service':
					objectToSave = 'terminal_services';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'currency_markup':
					objectToSave = 'currency_markup';
					requestPath = `/api/v1/${objectToSave}/`;
					break;

				case 'currency_markup_total':
					objectToSave = 'currency_markup';
					requestPath = `/api/v1/${objectToSave}/global`;
					break;

				case 'alert':
					objectToSave = 'alerts';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'note':
					objectToSave = 'notes';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'private_note':
					objectToSave = 'notes/private';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'description':
					objectToSave = 'descriptions';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'lang':
					objectToSave = 'langs';
					requestPath = `/api/v2/languages/${objectToSave}/`;
					break;

				case 'provider':
					objectToSave = 'providers';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'city':
					objectToSave = 'cities';
					requestPath = `/api/v1/${objectToSave}/`;
					break;

				case 'providerConnection':
					objectToSave = 'terminals';
					requestPath = `/api/v2/providers/${objectToSave}/`;
					break;

				case 'terminalConnection':
					objectToSave = 'providers';
					requestPath = `/api/v2/terminals/${objectToSave}/`;
					break;

				case 'variable_block':
					objectToSave = 'blocks';
					requestPath = `/api/v2/variables/${objectToSave}/`;
					break;

				case 'variable':
					objectToSave = 'blocks';
					requestPath = `/api/v2/variables/`;
					break;

				case 'provider_worker':
					objectToSave = 'workers';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'service':
					objectToSave = 'services';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'terminal_provider_service':
					objectToSave = 'services';
					requestPath = `/api/v2/providers/${objectToSave}/`;
					break;

				case 'comfort':
					objectToSave = 'comforts';
					requestPath = `/api/v2/${objectToSave}/`;
					break;

				case 'comfort_categories':
					objectToSave = 'categories';
					requestPath = `/api/v2/comforts/${objectToSave}/`;
					break;

				case 'terminal_additional_services':
					objectToSave = 'terminal_services';
					requestPath = `/api/v1/${objectToSave}/`;
					break;

				case 'provider_additional_services':
					objectToSave = 'additional';
					requestPath = `/api/v2/providers/services/${objectToSave}/`;
					break;

				case 'terminal_additional_service':
					requestPath = process.env.VUE_APP_API_V1 + `terminals/${this.editObject.terminal_id}/additional_services/`;
					break;

				case 'terminal_additional_service_element':
					requestPath = process.env.VUE_APP_API_V1 + `terminals/${this.editObject.terminal_id}/additional_services/`;
					break;

				case 'direction_additional_services':
					objectToSave = 'direction_services';
					requestPath = `/api/v1/${objectToSave}/`;
					break;

				default:
					reject('! UNSUPPORTED TYPE !');
					break;
			}

			if (this.editObject.id === undefined
				|| this.editObject.id === null
				|| this.editObject.id === 0)
			{
				let component = this;
				let sendObject = JSON.parse(JSON.stringify(component.editObject));
				if (entityToSave !== undefined)
					sendObject.entity = entityToSave;
				component.requestTools.prepareRequest(requestType.POST, sendObject);
				let requestPromise = this.requestTools.doRequest(requestPath + (component.editObject.postfix ? component.editObject.postfix : ''), returnMode.ALL);
				requestPromise.then(value => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.success');
					toastItem.description = i18n.t('alerts.success_save');
					toastItem.status = 'success';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					resolve({'type': 'create', 'id': value.data.data.model_id});
				}).catch(error => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.error');
					toastItem.description = i18n.t('alerts.error_save');
					toastItem.description += "<br><br>";
					toastItem.description += error.message ? error.message : error.error;
					toastItem.description += "<br>";
					if (error.errors !== undefined) {
						for (const [value] of Object.entries(error.errors)) {
							toastItem.description += " " + value + "<br>";
						}
					}
					toastItem.status = 'danger';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					reject(error);
				});
			} else {
				let component = this;
				component.requestTools.prepareRequest(requestType.PUT, component.editObject);
				let requestPromise = component.requestTools.doRequest(
					requestPath + component.editObject.id + (component.editObject.postfix ? component.editObject.postfix : ''), returnMode.ALL);
				requestPromise.then(value => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.success');
					toastItem.description = i18n.t('alerts.success_save');
					toastItem.status = 'success';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					resolve({'type': 'update', 'id': value.data.data.model_id});
				}).catch(error => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.error');
					toastItem.description = i18n.t('alerts.error_save');
					toastItem.description += "<br><br>";
					toastItem.description += error.message ? error.message : error.error;
					toastItem.description += "<br>";
					if (error.errors !== undefined) {
						for (const [value] of Object.entries(error.errors)) {
							toastItem.description += " " + value + "<br>";
						}
					}
					toastItem.status = 'danger';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					reject(error);
				});
			}
		});
	}

	/**
	 * Creates or updates auth object
	 * @returns {Promise<object>}
	 */
	async saveAuth (authType = 'auth') {
		return new Promise((resolve, reject) => {
			let requestPath = '';
			let entityToSaveIn = '';
			let objectToSave = '';
			let entityToSave = '';

			try {
				entityToSaveIn = this.editObject.entity.name;
				entityToSave = this.editObject.saveEntity;
			} catch (e) {
				reject(e);
			}

			switch (entityToSaveIn) {
				case 'airport':
					objectToSave = 'airports';
					requestPath = `/api/v2/admin/${objectToSave}/${authType}`;
					break;

				case 'terminal':
					objectToSave = 'terminals';
					requestPath = `/api/v2/admin/${objectToSave}/${authType}/`;
					break;

				case 'terminal_service':
					objectToSave = 'terminal_services';
					requestPath = `/api/v2/admin/${objectToSave}/${authType}/`;
					break;

				case 'alert':
					objectToSave = 'alerts';
					requestPath = `/api/v2/${objectToSave}/${authType}/`;
					break;

				case 'note':
					objectToSave = 'notes';
					requestPath = `/api/v2/${objectToSave}/${authType}/`;
					break;

				case 'private_note':
					objectToSave = 'notes/private';
					requestPath = `/api/v2/${objectToSave}/${authType}/`;
					break;

				case 'description':
					objectToSave = 'descriptions';
					requestPath = `/api/v2/${objectToSave}/${authType}/`;
					break;

				case 'provider':
					objectToSave = 'providers';
					requestPath = `/api/v2/${objectToSave}/${authType}/`;
					break;

				default:
					reject('! UNSUPPORTED TYPE !');
					break;
			}

			if (this.editObject.id === undefined
				|| this.editObject.id === null
				|| this.editObject.id === 0)
			{
				let component = this;
				let sendObject = JSON.parse(JSON.stringify(component.editObject));
				if (entityToSave !== undefined)
					sendObject.entity = entityToSave;
				component.requestTools.prepareRequest(requestType.POST, sendObject);
				let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
				requestPromise.then(value => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.success');
					toastItem.description = i18n.t('alerts.success_save');
					toastItem.status = 'success';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					resolve({'type': 'create', 'id': value.data.data.model_id});
				}).catch(error => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.error');
					toastItem.description = i18n.t('alerts.error_save');
					toastItem.description += "<br><br>";
					toastItem.description += error.message;
					toastItem.description += "<br>";
					if (error.errors !== undefined) {
						for (const [value] of Object.entries(error.errors)) {
							toastItem.description += " " + value + "<br>";
						}
					}
					toastItem.status = 'danger';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					reject(error);
				});
			} else {
				let component = this;
				component.requestTools.prepareRequest(requestType.PUT, component.editObject);
				let requestPromise = component.requestTools.doRequest(
					requestPath + component.editObject.id, returnMode.ALL);
				requestPromise.then(value => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.success');
					toastItem.description = i18n.t('alerts.success_save');
					toastItem.status = 'success';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					resolve({'type': 'update', 'id': value.data.data.model_id});
				}).catch(error => {
					let toastItem = {};
					toastItem.title = i18n.t('alerts.error');
					toastItem.description = i18n.t('alerts.error_save');
					toastItem.description += "<br><br>";
					toastItem.description += error.message;
					toastItem.description += "<br>";
					if (error.errors !== undefined) {
						for (const [value] of Object.entries(error.errors)) {
							toastItem.description += " " + value + "<br>";
						}
					}
					toastItem.status = 'danger';
					toastItem.time = 4000;

					component.toastsStore.addToast(toastItem);
					reject(error);
				});
			}
		});
	}

	/**
	 * Returns an array of airports based on input
	 * @param airport_id {string || number}
	 * @param input {string}
	 * @param elements {string}
	 * @param page {string || number}
	 * @param pageSize {string || number}
	 * @param sortBy {string}
	 * @return {Promise<array>}
	 */
	async getTerminals (airport_id, input, elements = '', page = 1, pageSize = 10, sortBy = 'name,asc') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/terminals';
			let dataToSend = {
				'airport_id': airport_id,
				'sortBy': sortBy,
				'page': page,
				'pageSize': pageSize,
				'elements': elements === undefined || elements === null ? '' : elements,
				'search': input === undefined || input === null ? '' : input
			};

			if (elements === '')
				delete dataToSend.elements;

			this.requestTools.prepareRequest(requestType.GET, dataToSend);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of airports based on id
	 * @param airport_id {string || number}
	 * @param elements {string}
	 * @param page {string || number}
	 * @param pageSize {string || number}
	 * @param sortBy {string}
	 * @return {Promise<array>}
	 */
	async getTerminalsById (airport_id, elements = '', page = 1, pageSize = 10, sortBy = 'name,asc') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/terminals';
			let dataToSend = {
				'sortBy': sortBy,
				'page': page,
				'pageSize': pageSize,
				'elements': elements === undefined || elements === null ? '' : elements,
				'airport_id': airport_id === undefined || airport_id === null ? '' : airport_id
			};

			if (elements === '')
				delete dataToSend.elements;

			this.requestTools.prepareRequest(requestType.GET, dataToSend);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a terminal object or rejects if does not exists
	 * @param terminal_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getTerminal (terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/terminals/' + terminal_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a provider object or rejects
	 * @param provider_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getProvider (provider_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/' + provider_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of providers
	 * @param input {string}
	 * @param elements {string}
	 * @param page {string || number}
	 * @param pageSize {string || number}
	 * @param sortBy {string}
	 * @return {Promise<array>}
	 */
	async getProviders (input = '' , elements = '', page = 1, pageSize = 10, sortBy = 'name,asc') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers';
			let dataToSend = {
				'sortBy': sortBy,
				'page': page,
				'pageSize': pageSize,
				'elements': elements === undefined || elements === null ? '' : elements,
				'search': input === undefined || input === null ? '' : input
			};

			if (elements === '')
				delete dataToSend.elements;

			this.requestTools.prepareRequest(requestType.GET, dataToSend);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Saves variables, should be a better path but have no idea yet
	 */
	async saveVariables() {
		return new Promise((resolve, reject) => {
			let requestPath = '';
			let entityToSaveIn = this.editObject.entity.name;
			let objectToSave = '';
			let entityToSave = this.editObject.saveEntity;
			let entityToWorkWith = 'variables';

			switch (entityToSaveIn) {
				case 'airport':
					objectToSave = 'airports';
					requestPath = `/api/v2/admin/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'terminal':
					objectToSave = 'terminals';
					requestPath = `/api/v2/admin/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'terminal_service':
					objectToSave = 'terminal_services';
					requestPath = `/api/v2/admin/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'alert':
					objectToSave = 'alerts';
					requestPath = `/api/v2/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'note':
					objectToSave = 'notes';
					requestPath = `/api/v2/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'private_note':
					objectToSave = 'notes/private';
					requestPath = `/api/v2/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'description':
					objectToSave = 'descriptions';
					requestPath = `/api/v2/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'lang':
					objectToSave = 'langs';
					requestPath = `/api/v2/languages/${objectToSave}/${entityToWorkWith}`;
					break;

				case 'provider':
					objectToSave = 'providers';
					requestPath = `/api/v2/${objectToSave}/${entityToWorkWith}`;
					break;

				default:
					reject('! UNSUPPORTED TYPE !');
					break;
			}

			let component = this;
			let sendObject = JSON.parse(JSON.stringify(component.editObject));
			if (entityToSave !== undefined)
				sendObject.entity = entityToSave;
			component.requestTools.prepareRequest(requestType.POST, sendObject);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromise.then(value => {
				let toastItem = {};
				toastItem.title = i18n.t('alerts.success');
				toastItem.description = i18n.t('alerts.success_save');
				toastItem.status = 'success';
				toastItem.time = 4000;

				component.toastsStore.addToast(toastItem);
				resolve({'type': 'create', 'id': value.data.data.model_id});
			}).catch(error => {
				let toastItem = {};
				toastItem.title = i18n.t('alerts.error');
				toastItem.description = i18n.t('alerts.error_save');
				toastItem.description += "<br><br>";
				toastItem.description += error.message;
				toastItem.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toastItem.description += " " + value + "<br>";
					}
				}
				toastItem.status = 'danger';
				toastItem.time = 4000;

				component.toastsStore.addToast(toastItem);
				reject(error);
			});
		});
	}

	/**
	 * Returns an array of client types
	 * @returns {Promise<array>}
	 */
	async getClientTypes() {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'client_types';
			this.requestTools.prepareRequest(requestType.GET);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of sources
	 * @returns {Promise<array>}
	 */
	async getSources() {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'sources';
			this.requestTools.prepareRequest(requestType.GET, {'status': 1});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of user types
	 * @returns {Promise<array>}
	 */
	async getUserTypes() {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'user_types';
			this.requestTools.prepareRequest(requestType.GET, {'status': 1});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of importance types
	 * @param type {string}
	 * @returns {Promise<array>}
	 */
	async getImportanceTypes(type) {
		return new Promise((resolve, reject) => {
			let requestPath = `/api/v2/${type}/importants`;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of data tree
	 * @returns {Promise<array>}
	 */
	async getDataTree() {
		return new Promise((resolve, reject) => {
			let requestPath = `/api/v2/airports/services`;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of provider statuses
	 * @returns {Promise<object>}
	 */
	async getProviderStatuses () {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/status';
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of variable blocks based on given entity
	 * @param entity {string}
	 * @returns {Promise<object>}
	 */
	async getVariableBlocks (entity, status = 1) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'variables/blocks';
			this.requestTools.prepareRequest(requestType.GET, {
				entity: entity,
				status: status
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of variable types
	 * @returns {Promise<object>}
	 */
	async getVariableTypes () {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'variables/types';
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				resolve(value.data);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Creates media data depending on entity
	 * @param entity {string}
	 * @param model_id {string||id}
	 * @return {Promise<array>}
	 */
	async createServiceMediaData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let data = {};

			data.items = [];
			data.id = parseInt(model_id);
			data.entity_id = null;

			Promise.all([
				this.getEntities('true'),
				this.getMediaTypes('service'),
				this.getMedia(entity, model_id),
				this.getEntities('false')
			]).then(values => {
				let entitiesArray = values[0].concat(values[3]);
				data.entity_id = entitiesArray.find(dataItem => dataItem.name === entity).id
				let typesarray = values[1];
				let media = values[2];

				for (let i = 0; i < typesarray.length; i++) {
					let typesItem = {};
					typesItem.title = typesarray[i].title;
					typesItem.itemsTitle = typesarray[i].title;
					typesItem.type_id = typesarray[i].id;
					typesItem.id = data.id;
					typesItem.entity = entity;

					typesItem.items = media.data.filter(image => image.type_id === typesarray[i].id);
					data.items.push(typesItem);
				}

				resolve(data);
			}).catch(error => {
				console.log(error)
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Creates data for descriptions
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async createServiceDescriptionsData(entity = this.editObject.entity.name, model_id = this.editObject.id) {
		return new Promise((resolve) => {
			let descrData = {};

			descrData.model_id = parseInt(model_id);
			descrData.id = this.editObject.id;
			descrData.entity = entity;
			descrData.items = [];

			Promise.all([
				this.getDescriptions(entity, model_id),
				this.getServiceDescriptionsList('service', this.editObject.service_id)
			]).then(values => {
				let descrarray = values[0];
				let descrCategoryarray = values[1];

				for (let i = 0; i < descrCategoryarray.length; i++) {
					let descrItem = {};
					descrItem.title = descrCategoryarray[i].title;
					descrItem.items = descrarray.filter(descriptionItem =>
						parseInt(descriptionItem.type_id) === parseInt(descrCategoryarray[i].id));
					descrItem.entity = descrData.entity;
					descrItem.model_id = descrData.id;
					descrItem.type_id = descrCategoryarray[i].id;
					descrData.items.push(descrItem);
				}

				resolve(descrData);
			}).catch(error => {
				console.log(error);
				resolve([]);
			});
		})
	}

	/**
	 * Gets descriptions list depending on entity
	 * @param entity {string}
	 * @param model_id {string||number}
	 * @return {Promise<array>}
	 */
	async getServiceDescriptionsList(entity = 'service', model_id = this.editObject.id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'descriptions/lists';
			this.requestTools.prepareRequest(requestType.GET, {
				service_id: model_id,
				entity: entity,
			});
			let requestPromiseDescriptionsList = this.requestTools.doRequest(requestPath, returnMode.ALL);
			requestPromiseDescriptionsList.then(value => {
				resolve(value.data.data)
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a comfort object depending on its id
	 * @param model_id {string || number}
	 * @returns {Promise<object>}
	 */
	async getComfort (model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'comforts/' + parseInt(model_id);
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Syncs comforts with model
	 * @param model_id {String || Number}
	 * @param entity {String}
	 * @param comforts {Array}
	 * @returns {Promise<object>}
	 */
	async syncComforts (
		model_id,
		entity,
		comforts
	) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'comforts/sync';
			this.requestTools.prepareRequest(requestType.POST, {
				model_id: model_id,
				entity: entity,
				comforts: comforts
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				let toast = {};
				toast.title = i18n.t('alerts.success');
				toast.description = i18n.t('alerts.success_save');
				toast.status = 'success';
				toast.time = 4000;
				this.toastsStore.addToast(toast);
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				let toast = {};
				toast.title = i18n.t('alerts.error');
				toast.description = error;
				toast.status = 'danger';
toast.payload = error;
				toast.description += "<br><br>";
				toast.description += error.message;
				toast.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toast.description += " " + value + "<br>";
					}
				} else {
					console.log(error);
				}
				toast.time = 4000;
				this.toastsStore.addToast(toast);
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of comfort objects
	 * @param status {string}
	 * @returns {Promise<object>}
	 */
	async getComforts (status = 'true') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'comforts/';
			this.requestTools.prepareRequest(requestType.GET, {
				statuses: status
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns a comfort category object depending on its id
	 * @param model_id {string||number}
	 * @returns {Promise<object>}
	 */
	async getComfortCategory (model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'comforts/categories/' + parseInt(model_id);
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of comforts objects by entity and model_id
	 * @param model_id {String || Number}
	 * @param entity {String}
	 * @returns {Promise<Array<Object>>}
	 */
	async getComfortsByModel (
		model_id,
		entity
	) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'comforts/get';
			this.requestTools.prepareRequest(requestType.GET, {
				model_id: model_id,
				entity: entity
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					if (!value.data) {
						resolve(value);
					} else {
						resolve(value.data);
					}
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of connection objects by airport
	 * @param model_id {String || Number}
	 * @returns {Promise<Array<Object>>}
	 */
	async getConnectionsByAirport (
		model_id
	) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'admin/airports/' + model_id + '/providers';
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					if (!value.data) {
						resolve(value);
					} else {
						resolve(value.data);
					}
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an object provider direction
	 * @param direction_id {string}
	 * @returns {Promise<object>}
	 */
	async getProviderDirection (direction_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/directions/' + direction_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of comfort categories
	 * @param status {string}
	 * @returns {Promise<object>}
	 */
	async getComfortCategories (status = 'true') {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'comforts/categories/';
			this.requestTools.prepareRequest(requestType.GET, {
				statuses: status
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of directions of provider serivces of the
	 * terminal service
	 * @returns {Promise<array>}
	 * @param terminal_service_id {string||number}
	 * @param addCommon {boolean}
	 */
	async getServiceDirections (terminal_service_id, addCommon = true) {
		return new Promise((resolve, reject) => {
			this.getTerminalService(parseInt(terminal_service_id)).then(value => {
				let providerItems = value.service_providers;
				let promiseObjects = [];
				let itemsPromises = [];
				let finalItems = [];

				for (let i = 0; providerItems.length > i; i++) {
					itemsPromises.push(this.getServiceProviders(providerItems[i].id));
				}

				Promise.all([itemsPromises]).then(values => {
					for (let i = 0; values.length > i; i++) {
						for (let j = 0; values[i].length > j; j++) {
							promiseObjects.push(values[i][j]);
						}
					}

					if (addCommon) {
						let commonDirection = {};
						commonDirection.id = -1;
						commonDirection.type = 'common';
						commonDirection.title = '(Т) ' + i18n.t('titles.flight_common');
						finalItems.push(commonDirection);

						let additionalsDirection = {};
						additionalsDirection.id = -2;
						additionalsDirection.type = 'additionals';
						additionalsDirection.title = '(Т) ' + i18n.t('titles.flight_additionals');
						finalItems.push(additionalsDirection);
					}

					Promise.all(promiseObjects).then(directionValues => {
						for (let i = 0; directionValues.length > i; i++) {
							for (let j = 0; directionValues[i].directions.length > j; j++) {
								if (directionValues[i].directions[j].status) {
									directionValues[i].directions[j].provider = directionValues[i].provider;
									finalItems.push(directionValues[i].directions[j]);
								}
							}
						}

						finalItems = finalItems.sort((a, b) => a.provider_service_id - b.provider_service_id || Number (a.international) - Number(b.international));

						resolve(finalItems);
					});
				});
			}).catch(e => {
				reject(e);
			});
		});
	}

	/**
	 * Returns an array of directions of provider serivces
	 * @returns {Promise<array>}
	 * @param provider_service_id {string||number}
	 * @param addCommon {boolean}
	 */
	async getProviderServiceDirections (provider_service_id, addCommon = true) {
		return new Promise((resolve) => {
			let promiseObjects = [];
			let itemsPromises = [];
			let finalItems = [];

			itemsPromises.push(this.getServiceProviders(provider_service_id))

			Promise.all([itemsPromises]).then(values => {
				for (let i = 0; values.length > i; i++) {
					for (let j = 0; values[i].length > j; j++) {
						promiseObjects.push(values[i][j]);
					}
				}

				if (addCommon) {
					let commonDirection = {};
					commonDirection.id = -1;
					commonDirection.type = 'common';
					commonDirection.title = '(Т) ' + i18n.t('titles.flight_common');
					finalItems.push(commonDirection);

					let additionalsDirection = {};
					additionalsDirection.id = -2;
					additionalsDirection.type = 'additionals';
					additionalsDirection.title = '(Т) ' + i18n.t('titles.flight_additionals');
					finalItems.push(additionalsDirection);
				}

				Promise.all(promiseObjects).then(directionValues => {
					for (let i = 0; directionValues.length > i; i++) {
						for (let j = 0; directionValues[i].directions.length > j; j++) {
							if (directionValues[i].directions[j].status) {
								directionValues[i].directions[j].provider = directionValues[i].provider;
								finalItems.push(directionValues[i].directions[j]);
							}
						}
					}

					finalItems = finalItems.sort((a, b) => a.provider_service_id - b.provider_service_id || Number (a.international) - Number(b.international));

					resolve(finalItems);
				});
			})
		});
	}

	/**
	 * Returns an array of providers for additional services
	 * terminal service
	 * @returns {Promise<array>}
	 * @param terminal_id {string||number}
	 */
	async getAdditionalServicesWithProviders (terminal_id) {
		return new Promise((resolve, reject) => {
			Promise.all([
				this.getTerminal(terminal_id),
				this.getServices('0')
			]).then(values => {
				let data = {};
				// Get providers
				data.providers = values[0].providers;
				// Get services
				data.services = values[1];
				// Compare services with providers
				for (let i = 0; i < data.providers.length; i++) {
					// items
				}
			}).catch(e => {
				reject(e);
			});
		});
	}

	/**
	 * Returns an array of connected additional services
	 * @returns {Promise<array>}
	 * @param terminal_id {string||number}
	 */
	async getConnectedTerminalAdditionalServices (terminal_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V1 + 'terminals/' + terminal_id + '/additional_services';
			this.requestTools.prepareRequest(requestType.GET, {
				pageSize: 99
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			Promise.all([
				requestPromise
			]).then(value => {
				if (value[0].data.data !== undefined) {
					resolve(value[0].data.data);
				} else {
					resolve(value[0].data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of objects of directon
	 * terminal service
	 * @returns {Promise<array>}
	 * @param direction_id {string||number}
	 */
	async getProviderAdditionalDirectionServices (direction_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/additionals';
			this.requestTools.prepareRequest(requestType.GET, {
				provider_service_id: direction_id
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			Promise.all([
				requestPromise
			]).then(value => {
				if (value[0].data.data !== undefined) {
					resolve(value[0].data.data);
				} else {
					resolve(value[0].data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Gets logo by provider ID
	 * @param entity {String}
	 * @param model_id {String||Number}
	 * @returns {Promise<array>}
	 */
	async getLogo(entity, model_id) {
		return new Promise((resolve, reject) => {
			let promisesArray = [];

			if (entity === 'provider') {
				promisesArray.push(this.getProvider(model_id));
			} else if (entity === 'service') {
				promisesArray.push(this.getServices('1'));
				promisesArray.push(this.getServices('0'));
			}

			Promise.all(promisesArray).then(values => {
				let finalItems = [];
				let finalItem = {};
				if (values.length > 1) {
					for (let i = 0; i < values.length; i++) {
						finalItems = finalItems.concat(values[i]);
					}
					finalItem = finalItems.find(item => item.id === model_id);
				} else {
					finalItem = values[0];
				}

				resolve(finalItem.logo);
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve({});
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an object of additional service of direction
	 * terminal service
	 * @param service_id {string||number}
	 * @returns {Promise<object>}
	 */
	async getProviderDirectionAdditional (service_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/directions/additionals/' + service_id;
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			Promise.all([
				requestPromise
			]).then(value => {
				if (value[0].data.data !== undefined) {
					resolve(value[0].data.data);
				} else {
					resolve(value[0].data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns an array of additional services
	 * terminal service
	 * @returns {Promise<array>}
	 * @param terminal_id {string||number}
	 */
	async getAdditionalServicesProvider (provider_service_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/additional/all';
			this.requestTools.prepareRequest(requestType.GET, {
				provider_service_id: provider_service_id
			});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			Promise.all([
				requestPromise
			]).then(value => {
				if (value[0].data.data !== undefined) {
					resolve(value[0].data.data);
				} else {
					resolve(value[0].data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Returns entity values for a price table field
	 * @returns {Promise<object>}
	 * @param entity
	 * @param value
	 */
	async getServiceEntity (entity, value) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'services/entities';

			this.requestTools.prepareRequest(requestType.GET, {
				entity: entity,
				value: Array.isArray(value) ? value.join(',') : value
			});

			const currentRequest = JSON.parse(JSON.stringify(requestPath + this.requestTools.requestData))
			this.cacheStore.getData(currentRequest).then(cachedData => {
				let cacheData = JSON.parse(JSON.stringify(cachedData))

				if (cacheData) {
					resolve(cacheData)
					return
				}

				this.requestTools.prepareRequest(requestType.GET, {
					entity: entity,
					value: Array.isArray(value) ? value.join(',') : value
				});
				let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

				requestPromise.then(value => {
					if (value.data.data !== undefined) {
						let requestLife = new Date()
						requestLife.setMinutes(requestLife.getMinutes() + 50)
						requestLife = new Date(requestLife)
						this.cacheStore.writeData(currentRequest, value.data.data, requestLife.getTime()).then(() => {
							resolve(value.data.data)
						})
					} else {
						let requestLife = new Date()
						requestLife.setMinutes(requestLife.getMinutes() + 50)
						requestLife = new Date(requestLife)
						this.cacheStore.writeData(currentRequest, value.data, requestLife.getTime()).then(() => {
							resolve(value.data.data)
						})
					}
				}).catch(error => {
					if (error.error === 'Not Found') {
						resolve([]);
					} else {
						reject(error);
					}
				});
			})
		});
	}

	async getServiceProviders(model_id) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'providers/services/' + parseInt(model_id);
			this.requestTools.prepareRequest(requestType.GET, {});
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	async getPrices (model_id, type = 'terminal') {
		return new Promise((resolve, reject) => {
			let requestPath = '';

			if (type === 'main') {
				requestPath = process.env.VUE_APP_API_V2 + 'providers/services/directions/' + parseInt(model_id);
			} else {
				requestPath = process.env.VUE_APP_API_V2 + 'providers/services/' + parseInt(model_id);
			}

			this.requestTools.prepareRequest(requestType.GET, {});

			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					if (!value.data.data.prices) {
						resolve(value.data.data.price_data)
					} else {
						resolve(value.data.data.prices)
					}
				} else {
					if (!value.data.prices) {
						resolve(value.data.price_data)
					} else {
						resolve(value.data.prices)
					}
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	async getAdditionalPrices (model_id, type = 'terminal', object_id) {
		return new Promise((resolve, reject) => {
			let requestPath = '';

			if (type === 'additional_terminal') {
				requestPath = process.env.VUE_APP_API_V1 + 'terminals/' + object_id + '/additional_services/' + parseInt(model_id);
			} else if (type === 'additional_terminal_service') {
				requestPath = process.env.VUE_APP_API_V1 + 'terminal_services/' + object_id + '/additional_services/' + parseInt(model_id);
			} else if (type === 'additional_direction_service') {
				requestPath = process.env.VUE_APP_API_V1 + 'direction_services/' + object_id + '/additional_services/' + parseInt(model_id);
			}

			let dataObjectGet = {};

			this.requestTools.prepareRequest(requestType.GET, dataObjectGet);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					if (!value.data.data.prices) {
						resolve(value.data.data.price_data)
					} else {
						resolve(value.data.data.prices)
					}
				} else {
					if (!value.data.prices) {
						resolve(value.data.price_data)
					} else {
						resolve(value.data.prices)
					}
				}
			}).catch(error => {
				if (error.error === 'Not Found') {
					resolve([]);
				} else {
					reject(error);
				}
			});
		});
	}

	async sendPrice (type, elements) {
		return new Promise((resolve, reject) => {
			let requestPath = ''

			if (this.editObject.entity.name === 'provider_service') {
				requestPath = process.env.VUE_APP_API_V1 + 'provider/' + this.editObject.object_id + '/main_services/' + this.editObject.id + '/prices';
			} else if (this.editObject.entity.name === 'provider_direction') {
				requestPath = process.env.VUE_APP_API_V1 + 'directions/main_services/' + this.editObject.id + '/prices';
			}

			let dataObject = {
				model_id: this.editObject.id,
				entity: this.editObject.entity.name,
				elements: elements,
				type: type
			};

			this.requestTools.prepareRequest(requestType.PUT, dataObject);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			let component = this;

			requestPromise.then(value => {
				let toast = {};
				toast.title = i18n.t('alerts.success');
				toast.description = i18n.t('alerts.success_save');
				toast.status = 'success';
				toast.time = 4000;
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				resolve(value.data);
			}).catch(error => {
				let toast = {};
				toast.title = i18n.t('alerts.error');
				toast.description = error;
				toast.status = 'danger'
				toast.payload = error;
				toast.description += "<br><br>";
				toast.description += error.message;
				toast.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toast.description += " " + value + "<br>";
					}
				} else {
					console.log(error);
				}
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				reject(error);
			});
		});
	}

	async sendAdditionalPrice (type, priceType, elements) {
		return new Promise((resolve, reject) => {
			let requestPath = '';

			if (priceType === 'additional_terminal') {
				requestPath = process.env.VUE_APP_API_V1 + 'terminals/' + parseInt(this.editObject.object_id) + '/additional_services/' + parseInt(this.editObject.id) + '/prices'
			} else if (priceType === 'additional_terminal_service') {
				requestPath = process.env.VUE_APP_API_V1 + 'terminal_services/' + parseInt(this.editObject.object_id) + '/additional_services/' + parseInt(this.editObject.id) + '/prices'
			} else if (priceType === 'additional_direction_service') {
				requestPath = process.env.VUE_APP_API_V1 + 'direction_services/' + parseInt(this.editObject.object_id) + '/additional_services/' + parseInt(this.editObject.id) + '/prices'
			}

			let dataObject = {
				model_id: this.editObject.id,
				entity: this.editObject.entity.name,
				elements: elements,
				type: type
			};

			this.requestTools.prepareRequest(requestType.PUT, dataObject);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);
			let component = this;

			requestPromise.then(value => {
				let toast = {};
				toast.title = i18n.t('alerts.success');
				toast.description = i18n.t('alerts.success_save');
				toast.status = 'success';
				toast.time = 4000;
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				resolve(value.data);
			}).catch(error => {
				let toast = {};
				toast.title = i18n.t('alerts.error');
				toast.description = error;
				toast.status = 'danger';
				toast.payload = error;
				toast.description += "<br><br>";
				toast.description += error.message;
				toast.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toast.description += " " + value + "<br>";
					}
				} else {
					console.log(error);
				}
				toast.time = 4000;
				component.toastsStore.addToast(toast);
				reject(error);
			});
		});
	}
	async getAirportValidation(
		airport_id = 0,
		lang = 'RUS'
	) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'widget_test/airport_info';

			let dataObject = {
				airport_id: airport_id,
				lang: lang
			};

			this.requestTools.prepareRequest(requestType.GET, dataObject);
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	async getServiceValidation (
		service_ids,
		lang,
		adults,
		children,
		babies,
		additionals,
		currency,
		iterations,
		dates
	) {
		return new Promise((resolve, reject) => {
			let requestPath = process.env.VUE_APP_API_V2 + 'widget_test/service_orders';

			let dataObject = {
				service_ids: service_ids,
				lang: lang,
				adults: adults,
				childs: children,
				babies: babies,
				additionals: additionals,
				cur: currency,
				iteration: iterations,
				dates: dates
			};

			this.requestTools.prepareRequest(requestType.GET, dataObject, 'text/event-stream');
			let requestPromise = this.requestTools.doRequest(requestPath, returnMode.ALL);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				let toast = {};
				toast.title = i18n.t('alerts.error');
				toast.description = error;
				toast.status = 'danger';
toast.payload = error;
				toast.description += "<br><br>";
				toast.description += error.message;
				toast.description += "<br>";
				if (error.errors !== undefined) {
					for (const [value] of Object.entries(error.errors)) {
						toast.description += " " + value + "<br>";
					}
				} else {
					console.log(error);
				}
				toast.time = 4000;
				this.toastsStore.addToast(toast);
				reject(error);
			});
		});
	}

	/**
	 * Returns a service card
	 * @returns {Promise<object>}
	 * @param terminal_id {String||Number}
	 * @param flight_type {String||Number}
	 * @param service_id {String||Number}
	 * @param currency {String}
	 */
	getServicePrices(
		terminal_id,
		flight_type,
		service_id,
		currency = "RUB"
	) {
		return new Promise((resolve, reject) => {
			let requestPath = '/services/' + service_id;

			let dataObject = {
				terminal_id: parseInt(terminal_id),
				cur: currency,
				lang: "RUS"
			};

			this.requestTools.prepareRequest(requestType.GET, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_WIDGET + process.env.VUE_APP_API_V1
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/* Copy Services */
	/**
	* Copies services by its id and elements
	* @returns {Promise<object>}
	* @param service_origin_id {String||Number}
	* @param service_destination_id {String||Number}
	* @param elements {Array<String>}
	* @param service_type {Object}
	*/
	copyMainServiceElements(
		service_origin_id,
		service_destination_id,
		elements,
		service_type
	) {
		return new Promise((resolve, reject) => {
			let requestPath = 'services/copy/' + service_type.entity;

			let dataObject = {
				copy_service_id: parseInt(service_origin_id),
				receiving_service_id: service_destination_id ? service_destination_id[0] : service_destination_id,
				receiving_service_ids: service_destination_id,
				elements: elements,
				lang: "RUS"
			};

			this.requestTools.prepareRequest(requestType.POST, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V2
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/* Copy Services */
	/**
	 * Copies modifiers by its id and elements
	 * @returns {Promise<object>}
	 * @param service_origin_id {String||Number}
	 * @param service_destination_id {String||Number}
	 * @param elements {Array<String>}
	 * @param service_type {Object}
	 */
	copyMainServiceModifiers(
		service_origin_id,
		service_destination_id,
		elements,
		service_type
	) {
		return new Promise((resolve, reject) => {
			let requestPath = 'services/copy/modifications';

			let dataObject = {
				copy_service_id: parseInt(service_origin_id),
				receiving_service_ids: service_destination_id,
				elements: elements,
				entity: service_type.entity,
				lang: "RUS"
			};

			this.requestTools.prepareRequest(requestType.POST, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V2
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/**
	 * Copies additional services by its id and elements
	 * @returns {Promise<object>}
	 * @param service_origin_ids {String||Number}
	 * @param terminal_origin_id {String||Number}
	 * @param terminal_destination_ids {Array}
	 * @param elements {Array<String>}
	 */
	copyMainServiceAdditional(
		service_origin_ids,
		terminal_origin_id,
		terminal_destination_ids,
		elements
	) {
		return new Promise((resolve, reject) => {
			let requestPath = 'additional_services/copy/terminal_services';

			let dataObject = {
				copy_terminal_id: parseInt(terminal_origin_id),
				copy_service_ids: service_origin_ids,
				receiving_terminal_ids: terminal_destination_ids,
				elements: elements,
				lang: "RUS"
			};

			this.requestTools.prepareRequest(requestType.POST, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V1
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/**
	 * Get services copy elements
	 * @returns {Promise<Array<string>>}
	 */
	getCopyServiceElements() {
		return new Promise((resolve, reject) => {
			let requestPath = 'services/copy/info/elements';

			let dataObject = {

			};

			this.requestTools.prepareRequest(requestType.GET, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V2
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/**
	 * Get services copy types
	 * @returns {Promise<Array<string>>}
	 */
	getCopyServiceTypes() {
		return new Promise((resolve, reject) => {
			let requestPath = 'services/copy/info/entites';

			let dataObject = {

			};

			this.requestTools.prepareRequest(requestType.GET, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V2
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	getCopyServiceAdditionalTypes() {
		return new Promise((resolve, reject) => {
			let requestPath = 'additional_services/copy/elements';

			let dataObject = {

			};

			this.requestTools.prepareRequest(requestType.GET, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V1
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/**
	 * Get services copy modifiers
	 * @returns {Promise<Array<string>>}
	 */
	getCopyServiceModifiers () {
		return new Promise((resolve, reject) => {
			let requestPath = 'services/copy/info/modifications';

			let dataObject = {

			};

			this.requestTools.prepareRequest(requestType.GET, dataObject);
			let requestPromise = this.requestTools.doRequest(
				requestPath,
				returnMode.ALL,
				"application/json",
				process.env.VUE_APP_API_SERVICE + process.env.VUE_APP_API_V2
			);

			requestPromise.then(value => {
				if (value.data.data !== undefined) {
					resolve(value.data.data);
				} else {
					resolve(value.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	/* Copy Services */
}